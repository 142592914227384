<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div class="whyus-root">
      <div class="container">
        <div class="title-dashed">{{ values.title }}</div>
        <div class="whyus-items">
          <WhyUsItem
            v-for="(item, idx) in values.items"
            :key="`item-${idx}`"
            :item="item"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import WhyUsItem from '~/cms/blocks/RmmWhyUs/WhyUsItem.vue';

const { $customSectionClasses } = useNuxtApp();

export type WhyUsBlockItem = {
  icon: number;
  title: string
}

type WhyUsBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    items: WhyUsBlockItem[]
    title: string
  },
  cfgs: SectionTypes;
};

const props = defineProps<WhyUsBlockType>();
</script>

<style scoped lang="scss">
.whyus-root {
  padding: 40px 0;
}

.whyus-items {
  display: flex;
  gap: 56px 74px;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .whyus-items {
    gap: 32px;
  }

  .whyus-root {
    padding-bottom: 88px;
  }
}

</style>
